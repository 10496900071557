<template>
  <div
      v-if="isPath('/DISABLE-THIS-POPUP')"
      class="w-72 overflow-hidden text-white filter drop-shadow rounded-md absolute text-left"
      style="background-color: #43064b"
      :class="{
        'block': display,
        'hidden': !display
      }"
  >
    <p class="text-center text-4xl absolute w-full top-6 font-kanit">{{data.name}}</p>
    <img :src="'/heroes/' + data.image" class="h-max w-max rounded-t-md">
    <div class="p-5 font-nunito">
      <div class="flex items-center">
        <UserGroupIcon class="h-5 w-5 mr-1" />
        <p>Indbyggere: <strong>{{data.residents}}</strong></p>
      </div>
      <div class="flex items-center">
        <ArrowsExpandIcon class="h-5 w-5 mr-1" />
        <p>Areal: <strong>{{data.area}}</strong> km²</p>
      </div>
      <div class="flex items-center">
        <BriefcaseIcon class="h-5 w-5 mr-1" />
        <p>Virksomheder: <strong>{{data.companies}}</strong></p>
      </div><br />
      <div class="flex items-center">
        <CakeIcon class="h-5 w-5 mr-1" />
        <p>Gns. Alder på Bolig: <strong>{{data.houseAge}} år</strong></p>
      </div>
      <div class="flex relative items-baseline">
        <div class="overflow-hidden w-10/12 h-2 mb-4 mr-2 text-xs flex rounded bg-gray-200">
          <div style="width:85%"
            class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-green-500">
          </div>
        </div>
        <p class="text-sm">85%</p>
      </div>

      <div class="flex items-center">
        <FireIcon class="h-5 w-5 mr-1" />
        <p>Antal Varmepumper: <strong>{{data.heatPumps}}</strong></p>
      </div>
      <div class="flex relative items-baseline">
        <div class="overflow-hidden w-10/12 h-2 mb-4 mr-2 text-xs flex rounded bg-gray-200">
          <div style="width:51%; background-color: #dee043;"
            class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center">
          </div>
        </div>
        <p class="text-sm">51%</p>
      </div>
      <div class="flex items-center">
        <ArrowsExpandIcon class="h-5 w-5 mr-1" />
        <p>Husstandsstørrelse: <strong>{{data.houseSize}} m2</strong></p>
      </div>
      <div class="flex relative items-baseline">
        <div class="overflow-hidden w-10/12 h-2 mb-4 mr-2 text-xs flex rounded bg-gray-200">
          <div style="width:15%; background-color: #d63c15;%"
            class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center">
          </div>
        </div>
        <p class="text-sm">15%</p>
      </div>
    </div>
  </div>
</template>

<script>

import { 
  FireIcon,
  UserGroupIcon,
  ArrowsExpandIcon,
  BriefcaseIcon,
  CakeIcon, } from '@heroicons/vue/outline'

export default {
  components: {
    FireIcon,
    UserGroupIcon,
    ArrowsExpandIcon,
    BriefcaseIcon,
    CakeIcon,
  },

  props: {
    data: Object,
    display: Boolean,
  },

  setup() {
    const isPath = (path) => window.location.pathname.match('^'+path) !== null

    return {
        isPath
    }
  }
}
</script>
