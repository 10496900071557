<template>
  <div id="chart2" style="width: 100%">
    <BarChart v-bind="barChartProps" />
  </div>
</template>

<script>
import { BarChart, useBarChart } from "vue-chart-3"
import { ref, computed, watch } from 'vue'
export default {
  components: {
    BarChart,
  },
  props: {
    area: String,
  },
  setup(props) {
    const randomData = () => Array.from({length: 24}, () => Math.floor((Math.random() * 100) + 50))
    const data = ref(randomData());

    const options = computed(() => ({
      responsive: true,
      interaction: {
        intersect: false,
      },
      scales: {
        y: {
          // beginAtZero: true,
          title: {
            display: true,
            text: 'Ikke godt                           Godt',
            font: {
              family: "Nunito",
              size: 14
            }
          },
          max: 100,
          min: 0,
          grid: {
            display: false,
            drawBorder: false,
            // color: function(context) {
            //   if (context.tick.value == 0) {
            //     return '#43064b';
            //   } else {
            //     return null;
            //   }
            // }
          },
          ticks: {
            display: false,
          },
          stacked: true,
        },
        x: {
          title: {
            display: true,
            text: 'Klokken',
            font: {
              family: "Nunito",
              size: 10
            }
          },
          grid: {
            display: true,
            drawBorder: false,
          },
          ticks: {
            display: true,
          },
          stacked: true,
        },
      },
      plugins: {
        tooltip: {
          enabled: false
        },
        legend: {
          display: false,
          labels: {
            filter: function(legendItem) {
                return legendItem.datasetIndex > 2 
            }
          }
        },
      },
    }));

    const graphData = computed ( () => {
      const data = [75, 80, 85, 80, 60, 25, 15, 30, 33, 35, 35, 37, 50, 45, 40, 35, 15, 5, 10, 15, 30, 45, 60, 60,]
      let currentHours = (new Date()).getHours();

      let hold = data.splice(0, currentHours)
      data.push(...hold)

      return data
    })

    const graphColors = computed (() => {
      let colors = []

      graphData.value.forEach((value) => {
        if (value > 70) {
          colors.push('#83a757')
        } else if (value > 60) {
          colors.push('#5f9d6b')
        } else if (value > 30) {
          colors.push('#49946f')
        } else if (value > 20) {
          colors.push('#388d72')
        } else {
          colors.push('#004255')
        }
      })

      return colors
    })

    const labels = computed(() => {
        let currentHours = (new Date()).getHours();
        let arr = Array.apply(0, Array(20)).map(function(_,b) { return b + 1; })
        return arr.map((v) => {
          let hours = ((currentHours + v) % 24).toString()
          return (hours.length === 1 ? '0' : '') + hours
        })
    });

    const chartData = computed(() => ({
      labels: labels.value,
      datasets: [
        {
          label: 'Vind',
          data: graphData.value,
          backgroundColor: graphColors.value,
          stack: 'Stack 0',
        },
        // {
        //   label: 'Sol',
        //   data: randomData(),
        //   backgroundColor: [
        //     "#83a757",
        //   ],
        //   stack: 'Stack 0',
        // },
        // {
        //   label: 'Biogas',
        //   data: randomData(),
        //   backgroundColor: "#83a757",
        //   stack: 'Stack 0',
        // },
      ]
    }));

    const { barChartProps } = useBarChart({
      chartData,
      options,
      height: 250,
    });

    watch(() => props.area, () => {
      shuffleData()
    })

    function shuffleData() {
      data.value = randomData()
    }

    return {
      shuffleData,
      barChartProps
    };
  }
}
</script>

<style scoped>

</style>