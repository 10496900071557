<template>
  <div class="bg-white mt-4 overflow-hidden shadow rounded-lg">
    <div class="bg-gray-50 px-2 py-2">
      <h1 class="font-kanit text-xl" style="color: #2c3e50;">
        Område udvikling over tid
      </h1>
    </div>
    <div class="p-3">
      <div class="w-full flex justify-between font-nunito">
        <div>1940</div>
        <div class="font-bold text-lg">{{ year }}</div>
        <div>Nu</div>
      </div>
      <input type="range" min="1940" max="2021" v-model="year" class="w-full">

      <div class="py-3">
        <table class="w-full text-left font-nunito">
          <tr>
            <th>Produktion:</th>
            <th>Nu</th>
            <th>År {{ year }}</th>
            <th>Ændring</th>
          </tr>
          <tr>
            <td>Solceller</td>
            <td>3140 kW</td>
            <td>{{ yearValue(3140, year, 1995) }} kW</td>
            <td>{{ diff(yearValue(3140, year, 1995), 3140)}} kW</td>
          </tr>
          <tr>
            <td>Vind</td>
            <td>4550 kW</td>
            <td>{{ yearValue(4550, year, 1950) }} kW</td>
            <td>{{ diff(yearValue(4550, year, 1950), 4550)}} kW</td>
          </tr>
          <tr>
            <td>Biogas</td>
            <td>12000 m<sup>3</sup></td>
            <td>{{ yearValue(12000, year, 1975) }} m<sup>3</sup></td>
            <td>{{ diff(yearValue(12000, year, 1975), 12000)}} m<sup>3</sup></td>
          </tr>
          <tr class="h-8">
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <th></th>
            <th>Nu</th>
            <th>År {{ year }}</th>
            <th>Ændring</th>
          </tr>
          <tr>
            <td>Antal indbyggere</td>
            <td>{{ statValue(2394) }}</td>
            <td>{{ yearValue(statValue(2394), year, 1800) }}</td>
            <td>{{ diff(yearValue(statValue(2394), year, 1800), statValue(2394))}}</td>
          </tr>
          <tr>
            <td>Antal husstande</td>
            <td>{{ statValue(784) }}</td>
            <td>{{ yearValue(statValue(784), year, 1800) }}</td>
            <td>{{ diff(yearValue(statValue(784), year, 1800), statValue(784))}}</td>
          </tr>
          <tr>
            <td>Gns. alder på boliger</td>
            <td>{{ statValue(56) }}</td>
            <td>{{ yearValue(statValue(56), year, 1700) }}</td>
            <td>{{ diff(yearValue(statValue(56), year, 1700), statValue(56))}}</td>
          </tr>
          <tr>
            <td>Antal oliefyr</td>
            <td>{{ statValue(285) }}</td>
            <td>{{ yearValue(statValue(285), year, 1800) }}</td>
            <td>{{ diff(yearValue(statValue(285), year, 1800), statValue(285))}}</td>
          </tr>
          <tr>
            <td>Antal gasfyr</td>
            <td>{{ statValue(235) }}</td>
            <td>{{ yearValue(statValue(235), year, 1970) }}</td>
            <td>{{ diff(yearValue(statValue(235), year, 1970), statValue(235))}}</td>
          </tr>
          <tr>
            <td>Antal elvarme</td>
            <td>{{ statValue(248) }}</td>
            <td>{{ yearValue(statValue(248), year, 1995) }}</td>
            <td>{{ diff(yearValue(statValue(248), year, 1995), statValue(248))}}</td>
          </tr>
          <tr>
            <td>Antal varmepumper</td>
            <td>{{ statValue(184) }}</td>
            <td>{{ yearValue(statValue(184), year, 2010) }}</td>
            <td>{{ diff(yearValue(statValue(184), year, 2010), statValue(184))}}</td>
          </tr>
          <tr>
            <td>Antal solcelleanlæg</td>
            <td>{{ statValue(294) }}</td>
            <td>{{ yearValue(statValue(294), year, 2000) }}</td>
            <td>{{ diff(yearValue(statValue(294), year, 2000), statValue(294))}}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'

export default {
  name: 'Omraade',

  props: {
    selectedArea: String,
  },

  setup(props) {
    const year = ref(2021)

    const yearValue = (value, year, minYear = 1990) => {
      const maxYear = 2021
      const rangeDiff = maxYear - minYear
      const yearDiff = maxYear - year

      const multiplier = (yearDiff / rangeDiff) * 100

      if (multiplier > 100) {
        return '-'
      }

      return Math.round((Math.floor((100 - multiplier)) / 100) * value)
    }

    const diff = (value, max) => {
      if (isNaN(value)) {
        return '-'
      }

      return - (max - value)
    }

    const statValue = (value) => {
      if (props.selectedArea !== '') {
        return Math.round(value / 6)
      }
      return value
    }

    return {
      year,
      yearValue,
      diff,
      statValue
    }
  }
}
</script>

<style scoped>

</style>