<template>
  <div id="chart" style="width: 100%">
    <BarChart v-bind="barChartProps" />
  </div>
</template>

<script>
import { BarChart, useBarChart } from "vue-chart-3"
import { ref, computed, watch } from 'vue'
export default {
  components: {
    BarChart,
  },
  props: {
    area: String,
  },
  setup(props) {
    const randomData = () => Array.from({length: 24}, () => Math.floor((Math.random() * 100) + 50))
    const randomDataNegative = () => Array.from({length: 24}, () => Math.floor((Math.random() * 100) + 50) * -1)
    const data = ref(randomData());
    const dataNegative = ref(randomDataNegative());

    const options = computed(() => ({
      responsive: true,
      interaction: {
        intersect: false,
      },
      scales: {
        y: {
          beginAtZero: true,
          title: {
            display: true,
            text: 'Import                      Produktion',
            font: {
              family: "Nunito",
              size: 14
            }
          },
          grid: {
            display: true,
            drawBorder: false,
            color: function(context) {
              if (context.tick.value == 0) {
                return '#43064b';
              } else {
                return null;
              }
            }
          },
          ticks: {
            display: true,
          },
          stacked: true,
        },
        x: {
          title: {
            display: true,
            text: 'Klokken',
            font: {
              family: "Nunito",
              size: 10
            }
          },
          grid: {
            display: true,
            drawBorder: false,
          },
          ticks: {
            display: true,
            fontFamily: "Kanit",
          },
          stacked: true,
        },
      },
      plugins: {
        legend: {
          display: true,
          labels: {
            filter: function(legendItem) {
                return legendItem.datasetIndex > 2 
            }
          }
        },
      },
    }));

    const labels = computed(() => {
        let currentHours = (new Date()).getHours();
        let arr = Array.apply(0, Array(20)).map(function(_,b) { return b + 1; })
        return arr.map((v) => {
          let hours = ((currentHours + v) % 24).toString()
          return (hours.length === 1 ? '0' : '') + hours
        })
    });

    const chartData = computed(() => ({
      labels: labels.value,//[(currentHour.value + 1) % 24, (currentHour.value + 1) % 24, (currentHour.value + 1) % 24, (currentHour.value + 1) % 24, (currentHour.value + 1) % 24, (currentHour.value + 1) % 24, (currentHour.value + 1) % 24, (currentHour.value + 1) % 24, (currentHour.value + 1) % 24, (currentHour.value + 1) % 24,'11', '12', '13', '14', '15', '16', '17', '18', '19', '20'],
      datasets: [
        {
          label: 'Vind',
          data: randomData(),
          backgroundColor: [
            "#83a757",
          ],
          stack: 'Stack 0',
        },
        {
          label: 'Sol',
          data: randomData(),
          backgroundColor: [
            "#83a757",
          ],
          stack: 'Stack 0',
        },
        {
          label: 'Biogas',
          data: randomData(),
          backgroundColor: "#49946f",
          stack: 'Stack 0',
        },
        {
          label: 'Vind',
          data: dataNegative.value,
          backgroundColor: [
            "#83a757",
          ],
          stack: 'Stack 0',
        },
        {
          label: 'Sol',
          data: randomDataNegative(),
          backgroundColor: [
            "#83a757",
          ],
          stack: 'Stack 0',
        },
        {
          label: 'Biogas',
          data: randomDataNegative(),
          backgroundColor: "#49946f",
          stack: 'Stack 0',
        },
        {
          label: 'Fossile',
          data: randomDataNegative(),
          backgroundColor: "#004255",
          stack: 'Stack 0',
        }
      ]
    }));

    const { barChartProps } = useBarChart({
      chartData,
      options,
      height: 250,
    });

    watch(() => props.area, () => {
      shuffleData()
    })

    function shuffleData() {
      data.value = randomData()
    }

    return {
      shuffleData,
      barChartProps
    };
  }
}
</script>

<style scoped>

</style>